@import "../../css-config/mixins.scss";
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&family=Lato:wght@400;700&display=swap");

.home {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;

  .div {
    //FIRST DIV
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-evenly;

    @include md {
      height: 80vh;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }

    .content {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: left;
      font-family: "Times New Roman", Times, serif;

      @include md {
        width: 50%;
      }

      #container {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        text-align: center;
      }
      h1 {
        font-size: 1.3rem;
        color: #fbc901;
        background-clip: text;
        -webkit-background-clip: text;
        animation: text 1s ease-in-out;
        position: relative;

        @include md {
          font-size: 1.8rem;
        }
      }

      @keyframes text {
        0% {
          left: -400px;
        }
        100% {
          left: 0px;
        }
      }

      p {
        width: 100%;
        font-size: 0.83rem;
        text-align: center;
        animation: Ptext 1s ease-in-out;
        position: relative;

        @include md {
          font-size: 1.1rem;
        }
      }

      @keyframes Ptext {
        0% {
          top: -200px;
          transform: scale(0);
        }
        80% {
          top: 0px;
          transform: scale(1.1);
        }
        100% {
          top: 0px;
          transform: scale(1);
        }
      }

      i {
        font-size: 0.65rem;
        color: black;
        font-weight: bold;
        animation: itext 0.01s ease-in-out;
        position: relative;

        @include md {
          text-align: left;
          font-size: 1.1rem;
        }
      }

      @keyframes itext {
        0% {
          left: -200px;
        }
        100% {
          left: 0px;
        }
      }
    }

    #main {
      height: 100%;
      width: 100%;
      display: flex;
      position: relative;

      @include md {
        width: 50%;
        display: flex;
        position: relative;
      }

      .intro_Pic {
        position: absolute;
        height: 100%;
        width: 100%;
        background: url("../../Assets/Intro_Picture.jpg") no-repeat center
          center;
        background-size: cover;

        @include md {
          height: 500px;
          width: 600px;
          background-size: contain;
        }
      }
      .intro_Pic:hover {
        cursor: pointer;
      }
    }
  }

  //SECOND DIV
  .intro {
    height: 50vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgba(0, 0, 0, 0.72);

    #header {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      font-size: 1.5rem;
      font-weight: 700;

      .design {
        height: 5px;
        width: 50px;
        background-color: #fbc901;
        border-radius: 10px;
      }
    }

    #sentence {
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      i {
        margin-top: -20px;
        margin-bottom: 20px;
        font-size: 0.9rem;
        font-weight: 700;
      }

      #head {
        font-size: 1.5rem;
        font-weight: 700;
        color: black;
      }

      p {
        text-align: center;
        font-size: 0.83rem;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.893);
        @include md {
          font-size: 1rem;
        }
      }
    }
  }

  //   THIRD DIV
  #service {
    height: 65vh;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    // background-color: red;

    @include md {
      height: 85vh;
      width: 100%;
    }

    #text-service {
      height: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      #ser-head {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        gap: 20px;

        p {
          font-size: 1.2rem;
          font-weight: 700;
          color: rgba(0, 0, 0, 0.6);
          margin: 50px 0px 20px 0px;

          @include md {
            font-size: 1.6rem;
          }
        }

        .ser-design {
          height: 5px;
          width: 50px;
          background-color: rgb(240, 252, 4);
          border-radius: 10px;
        }
      }

      h1 {
        width: 80%;
        font-size: 1rem;
        padding: 0;
        margin: 0;
        text-align: center;

        @include md {
          width: 80%;
          font-size: 1.4rem;
        }
      }
      p {
        width: 60%;
        font-size: 0.83rem;
        font-weight: 600;
        color: rgba(13, 13, 13, 0.708);
        text-align: center;

        @include md {
          font-size: 1rem;
        }
      }
    }

    #images-service {
      width: 96%;
      display: grid;
      margin: 5px;
      overflow-x: auto;
      overflow-y: hidden;
      gap: 40px;
      grid-auto-flow: column;
      grid-template-columns: repeat(auto-fit, minmax(150px));

      @include md {
        width: 70%;
        display: grid;
        margin: 5px;
        overflow-x: auto;
        overflow-y: hidden;
        gap: 40px;
        grid-auto-flow: column;
        grid-template-columns: repeat(auto-fit, minmax(150px));
      }
      &::-webkit-scrollbar {
        display: none;
      }

      scrollbar-width: none;

      img {
        height: 280px;
        width: 260px;
        object-fit: cover;
        border-radius: 5px;

        @include md {
          height: 250px;
          width: 240px;
          object-fit: cover;
          border-radius: 5px;
        }
      }
    }
  }

  //   FOURTH DIV
  #All-Service {
    height: 80vh;
    width: 100%;
    font-family: "Times New Roman", Times, serif;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: rgba(19, 17, 17, 0.03);

    @include md {
      height: 50vh;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
    }

    .serve {
      height: 225px;
      width: 300px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      h1 {
        width: 80%;
        font-size: 1.3rem;
        font-weight: 400;
        @include md {
          width: 80%;
          font-size: 1.5rem;
          font-weight: 520;
        }
      }

      i {
        font-size: 0.78rem;
        font-weight: 550;
        color: rgba(128, 128, 128, 0.707);
        @include md {
          font-size: 0.9rem;
        }
      }

      p {
        font-size: 0.83rem;
        text-align: center;
        @include md {
          font-size: 1.2rem;
        }
      }
    }
  }

  // FITH DIV
  .pyro {
    height: 150px;
    width: 100%;
    background-color: rgba(206, 210, 210, 0.03);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      font-size: 1.4rem;
      text-align: center;
      @include md {
        font-size: 1.3rem;
      }
    }

    #btn {
      padding: 8px;
      border-radius: 8px;
      border: 1px solid black;
      background-color: transparent;
      a {
        color: black;
        text-decoration: none;
        font-size: 1.2rem;
        font-weight: 600;
      }

      &:hover {
        background-color: rgba(137, 122, 122, 0.516);
      }
    }
  }
}
