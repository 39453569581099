@import "../../css-config/mixins.scss";

#contact {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Times New Roman", Times, serif;
  background-image: url("../../Assets/Contact_Banner.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @include md {
    height: 100vh;
    padding: 10px 0px 10px 0px;
  }

  #container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgb(0, 0, 0);
    margin: 0;

    h1 {
      width: 80%;
      text-align: center;
      font-size: 1.1rem;
      color: rgb(250, 250, 250);

      @include md {
        font-size: 1.5rem;
      }
    }

    form {
      height: 50%;
      width: 50%;
      padding: 40px;
      gap: 10px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      box-shadow: 8px 8px 30px 10px black;

      @include md {
        height: 60%;
        width: 50%;
      }

      input {
        height: 15px;
        padding: 18px;
        background: transparent;
        border: none;
        outline: none;
        background-color: rgba(255, 255, 255, 0.39);

        @include md {
          height: 20px;
          padding: 10px;
        }
      }
      input:hover {
        cursor: pointer;
        border-bottom: 1px solid rgb(10, 9, 9);
      }

      input:focus {
        background-color: rgba(255, 255, 255, 0.533);
        border-bottom: 2px solid #050505;
      }

      input:active {
        border-bottom: 2px solid #131212;
      }
      input::placeholder {
        font-size: 0.9rem;
        font-weight: bold;
        color: rgb(17, 16, 16);
      }

      #Date {
        font-size: 0.9rem;
        font-weight: 600;
        color: rgb(17, 16, 16);
        background-color: rgba(255, 255, 255, 0.523);
      }

      textarea {
        height: 130px;
        padding: 10px;
        border-width: 0px 2px 2px 0px;
        display: block;
        border-style: solid;
        border-color: rgba(0, 0, 0, 0.07);
        outline: none;
        background: transparent;
        background-color: rgba(255, 255, 255, 0.39);
      }
      textarea::placeholder {
        font-size: 0.9rem;
        font-weight: bold;
        color: rgb(17, 16, 16);
      }

      textarea:hover {
        border-width: 0px 2px 2px 0px;
        border-style: solid;
        border-color: black;
        cursor: pointer;
      }
      textarea:focus {
        background-color: rgba(255, 255, 255, 0.3);
      }
      textarea:active {
        border-bottom: 2px solid #131212;
      }

      button {
        background: #fff;
        color: white;
        border: none;
        padding: 5px;
        display: inline-block;
        font-size: 15px;
        font-weight: 600;
        width: 120px;
        text-transform: uppercase;
        cursor: pointer;
        transform: skew(-21deg);
        background: transparent;
      }

      span {
        display: inline-block;
        transform: skew(21deg);
      }

      button::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 100%;
        left: 0;
        background: rgb(246, 246, 246);
        opacity: 0;
        z-index: -1;
        transition: all 0.5s;
      }

      button:hover {
        color: #0b0b0b;
      }

      button:hover::before {
        left: 0;
        right: 0;
        opacity: 1;
      }
    }
  }
  .alter {
    font-size: 1.2rem;
    font-weight: bold;
    color: white;
    @include md {
      background-color: black;
    }

    h1 {
      text-align: center;
      font-size: 1.3rem;
    }
  }
}
