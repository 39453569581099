@import "../../css-config/mixins.scss";

#Gallery {
  height: auto;
  width: 100%;
  font-family: "Times New Roman", Times, serif;

  #content {
    height: 0px auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 60px;

    @include md {
      padding: 0;
    }

    iframe {
      height: 300px;
      width: 90%;
      object-fit: contain;

      @include md {
        height: 350px;
        width: 200px;
      }
    }
  }
  h2 {
    text-align: center;
    letter-spacing: 8px;
  }
  #photos {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0 4px;
    margin-bottom: 20px;

    .column {
      -ms-flex: 25%;
      flex: 25%;
      max-width: 80%;
      overflow: hidden;
      padding: 0 10px;

      img {
        margin-top: 8px;
        vertical-align: middle;
        width: 100%;
        transition: all 0.3s ease-in-out;
        animation: img 2s ease-in-out;
      }

      @keyframes img {
        0% {
          transform: scale(0);
        }
        80% {
          transform: scale(1.04);
        }
        100% {
          transform: scale(1);
        }
      }

      img:hover {
        cursor: pointer;
        transform: scale(1.05);
      }
    }
  }
}
