@import "../css-config/mixins.scss";
.footer {
  height: 350px;
  width: 100%;
  font-family: serif;

  @include md {
    height: 350px;
    width: 100%;
  }

  .content {
    height: 300px;
    width: 100%;
    gap: 20px;
    background: #23050b;
    background: -webkit-linear-gradient(
      43deg,
      #23050b 0%,
      #000000 50%,
      #000000 100%
    );
    background: linear-gradient(43deg, #23050b 0%, #000000 50%, #000000 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;

    .logo {
      height: auto;
      width: 100%;
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 130px;
        width: 220px;
        box-shadow: 100px 100px 100px 100px solid black;

        @include md {
          height: 110px;
          width: 200px;
        }
      }
    }

    .routers {
      height: auto;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      ul {
        width: 80%;
        display: flex;
        list-style: none;
        justify-content: center;
        align-items: center;
        gap: 22px;
        margin-right: 15px;

        @include md {
          display: flex;
          gap: 80px;
        }

        li {
          font-size: 0.6rem;
          position: relative;
          display: inline-block;
          cursor: pointer;
          a {
            color: white;
            text-decoration: none;
          }
          @include md {
            font-size: 1rem;
          }

          &:hover::after {
            cursor: pointer;
            width: 100%;
          }

          &::after {
            content: "";
            cursor: pointer;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            height: 2px;
            background-color: rgb(255, 255, 255);
            transition: width 0.3s ease;
          }
        }
      }
    }
  }

  .builder {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;

    .container {
      height: 100%;
      width: 90%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include md {
        width: 80%;
      }

      .copyRyt {
        height: fit-content;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.7rem;
        color: rgba(255, 255, 255, 0.566);

        @include md {
          width: 100%;
          font-size: 1rem;
        }
      }

      i {
        width: 40%;
        color: rgba(255, 255, 255, 0.6);
        font-size: 0.6rem;
        @include md {
          width: 20%;
          font-size: 0.9rem;
        }
      }
      i:hover {
        color: white;
      }
    }
  }
}
