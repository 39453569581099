.sticky {
  height: 40px;
  width: 40px;
  padding: 5px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: green;
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    text-decoration: none;
    color: goldenrod;
  }
}
