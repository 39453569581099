@import "../css-config/mixins.scss";

#loader {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Georgia, "Times New Roman", Times, serif;
  background-image: url("../Assets/I1.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  gap: 10px;

  img {
    height: 300px;
    width: 380px;
    border: none;
    border-radius: 50%;
    animation: loader 0.6s ease-in-out;

    @include md {
      height: 500px;
      width: 650px;
    }
  }
  @keyframes loader {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
}
