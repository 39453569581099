@import "../css-config/mixins.scss";

#nav {
  height: 10vh;
  width: 100%;
  background: #060606;
  background: -webkit-linear-gradient(52deg, #000000 0%, #250109e3 30%);
  background: linear-gradient(52deg, #000000 0%, #1f0208 100%);
  display: flex;

  @include md {
    height: 15vh;
    width: 100%;
    background: #060606;
    background: -webkit-linear-gradient(52deg, #000000 0%, #250109e3 30%);
    background: linear-gradient(52deg, #000000 0%, #1f0208 100%);
    position: relative;
    display: flex;
  }

  .logo {
    height: auto;
    width: 25%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    @include md {
      height: auto;
      width: 15%;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      height: 60px;
      width: 80px;
      @include md {
        height: 80px;
        width: 100px;
      }
    }
  }

  .social_media {
    height: auto;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 50px;

    @include md {
      height: auto;
      width: 40%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 80px;
    }

    a {
      color: goldenrod;
      text-decoration: none;
      font-size: 1.8rem;

      @include md {
        font-size: 2rem;
      }
    }
    a:hover {
      cursor: pointer;
    }
  }

  .icons {
    width: 20%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;

    @include md {
      display: none;
    }
    .icon {
      color: white;
      font-size: 2.5rem;
    }
  }

  #navbar {
    display: none;
    @include md {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    ul {
      height: 100%;
      width: 90%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 50px;

      a {
        margin: 0;
        color: white;
        padding: 10px;
        font-size: 1.2rem;
        text-decoration: none;
        transition:
          transform 0.2s ease,
          color 0.2s ease;
      }

      a:hover {
        transform: scale(1.2);
        cursor: pointer;
      }
    }
  }

  #sidebar {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url("../Assets/nav_bgd.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: grayscale(10%);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;

    &.show-sidebar {
      transform: translateY(0);
    }

    &.hide-sidebar {
      transform: translateY(-100%);
    }

    @include md {
      display: none;
    }

    .icon-2 {
      color: rgb(242, 236, 236);
      font-size: 2.5rem;
      font-weight: bolder;
      margin-top: 40px;
      margin-left: -180px;
      cursor: pointer;
    }

    ul {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-self: center;
      gap: 35px;

      a {
        text-decoration: none;
        color: rgb(255, 255, 255);
        font-size: 2.5rem;
        transition:
          transform 0.2s ease,
          color 0.2s ease;
        animation: navText 2s ease-in-out;
      }

      a:hover {
        transform: scale(1.29);
        cursor: pointer;
      }

      @keyframes navText {
        0% {
          transform: scale(-1);
        }
        80% {
          transform: scale(1.3);
        }
        100% {
          transform: scale(1);
        }
      }
    }
  }

  @keyframes sideBar {
    0% {
      top: -90vh;
    }
    100% {
      top: 20px;
    }
  }
}

body.sidebar-open {
  overflow: hidden; // Lock scrolling
}
