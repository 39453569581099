@import "../../css-config/mixins.scss";

.about {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include md {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .content {
    min-height: 60vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    @include md {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
    }

    #abt_img {
      height: 100%;
      width: 100%;

      img {
        height: 500px;
        width: 100%;

        @include md {
          height: 400px;
          width: 100%;
          object-fit: contain;
        }
      }
    }

    .bnr {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-family: "Times New Roman", Times, serif;
      position: relative;

      @include md {
        min-height: 85vh;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      h1 {
        height: 30px;
        width: 100%;
        font-size: 1.7rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        color: rgb(24, 10, 10);
        position: relative;
        animation: header 0.6s ease-in-out;

        @include md {
          width: 100%;
          color: black;
          font-size: 2rem;
        }
      }

      @keyframes header {
        0% {
          left: 400px;
        }
        100% {
          left: 0;
        }
      }

      #text-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;

        .txt {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          @include md {
            width: 100%;
          }

          h4 {
            color: rgb(219, 189, 21);
            font-size: 1rem;
            font-weight: bold;
            margin: 0;
            padding: 0;
            animation: subHeader 0.6s ease-in-out;

            @include md {
              font-size: 1.2rem;
            }
          }

          @keyframes subHeader {
            0% {
              transform: scale(0);
            }
            100% {
              transform: scale(1);
            }
          }

          h2 {
            margin: 5px 0;
            font-size: 1.8rem;
            letter-spacing: 5px;
            position: relative;
            animation: subheader2 0.6s ease-in-out;

            @include md {
              font-size: 1.8rem;
              letter-spacing: 6px;
              margin-top: 0;
            }
          }

          @keyframes subheader2 {
            0% {
              left: 400px;
            }
            100% {
              left: 0;
            }
          }

          p {
            width: 98%;
            font-size: 0.8rem;
            line-height: 1.1rem;

            @include md {
              width: 96%;
              font-size: 1rem;
            }
          }
        }

        .txticon {
          height: auto;
          display: flex;
          gap: 10px;
          flex-direction: row;
          justify-content: center;
          align-items: start;

          .icon {
            display: flex;
            justify-content: center;
            align-items: center;

            i {
              margin: 3px 8px 0 0;
              font-size: 2rem;
              color: black;
              animation: icon 0.6s ease-in-out;
            }

            @keyframes icon {
              0% {
                transform: scale(0);
              }
              100% {
                transform: scale(1);
              }
            }
          }

          .text1 {
            width: 80%;

            h2 {
              margin: 0;
              font-size: 1.3rem;
              letter-spacing: 0;
            }
          }
        }
      }
    }
  }

  #reviews {
    min-height: 60vh;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2rem;

    @include md {
      min-height: 40vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    h1 {
      font-size: 2.5rem;
      margin-bottom: 1rem;
      color: #0f0f0f;
    }

    #video_container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 40px;
      // background-color: red;

      @include md {
        display: flex;
        flex-direction: row;
      }

      .video {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
        object-fit: cover;

        @include md {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
